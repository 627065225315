<template>
    <div>
        <b-container
            fluid
        >
            <b-row
                class="justify-content-md-center"
            >
                <b-col
                    cols="12"
                    md="auto"
                >
                    <span
                        style="font-size: 60px;"
                    >
                        FULFILL<span style="color: rgb(248, 150, 36)">IN</span>
                    </span>
                </b-col>
            </b-row>
            <b-row
                class="justify-content-md-center"
            >
                <b-col
                    cols="12"
                    md="auto"
                    style="font-size: 16px;"
                >
                    <template
                        v-if="Access.checkAccess('settings.inventory', Access.LEVEL_READ) && Features.checkFeature('inventory') === true"
                    >
                        <router-link
                            :to="{ name: 'inventory.index' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            Inventory
                        </router-link>
                        <span style="color: rgb(248, 150, 36)">|</span>
                    </template>

                    <template
                        v-if="Access.checkAccess('shipments', Access.LEVEL_READ) === true"
                    >
                        <router-link
                            :to="{ name: 'shipments.index' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            Shipments
                        </router-link>
                        <span style="color: rgb(248, 150, 36)">|</span>
                    </template>
                    <template
                        v-if="Access.checkAccess('settings.project', Access.LEVEL_READ) === true"
                    >
                        <router-link
                            :to="{ name: 'settings.project' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            Project settings
                        </router-link>
                        <span style="color: rgb(248, 150, 36)">|</span>
                    </template>
                    <template
                        v-if="Access.checkAccess('settings.integrations', Access.LEVEL_READ) === true"
                    >
                        <router-link
                            :to="{ name: 'settings.integrations' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            Integrations
                        </router-link>
                        <span style="color: rgb(248, 150, 36)">|</span>
                    </template>
                    <template
                        v-if="Access.checkAccess('settings.users', Access.LEVEL_READ) === true"
                    >
                        <router-link
                            :to="{ name: 'settings.users' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            Users
                        </router-link>
                        <span style="color: rgb(248, 150, 36)">|</span>
                    </template>
                    <template
                        v-if="Access.checkAccess('settings.user_groups', Access.LEVEL_READ) === true"
                    >
                        <router-link
                            :to="{ name: 'settings.user-groups' }"
                            :style="isDark ? 'color: #b4b7bd !important;' : 'color: #6e6b7b !important;'"
                        >
                            User groups
                        </router-link>
                    </template>
                </b-col>
            </b-row>
            <b-row
                class="justify-content-md-center"
            >
                <b-col
                    v-if="isDark"
                    cols="12"
                    md="auto"
                >
                    <b-img
                        src="../assets/images/illustration/coming-soon-black.svg"
                        alt="Technical"
                        fluid
                    />
                </b-col>
                <b-col
                    v-else
                    cols="12"
                    md="auto"
                >
                    <b-img
                        src="../assets/images/illustration/coming-soon-white.svg"
                        alt="Technical"
                        fluid
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {
    BContainer,
    BRow,
    BCol,
    BImg,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import Access from '@/services/Access'
import Features from '@/services/Features'

export default {
    components: {
        BContainer,
        BCol,
        BRow,
        BImg,
    },
    data() {
        return {
            Access,
            Features,
        }
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
}
</script>
